<template>
  <div class="row categories my-4">
    <div class="col swiper-full-width">
      <swiper :options="swiperOptions">
        <div slot="button-prev" class="swiper-button-prev "></div>
        <div slot="button-next" class="swiper-button-next "></div>
        <swiper-slide class="item p-4 text-center">
          <b-img
            src="@/assets/foravila/images/services/economic-cars.jpeg"
            alt="Economic Rent a Car in Mallorca"
          ></b-img>
          <h5 class="font-weight-black text-uppercase mt-3">
            {{ $t("pages.carRentalsService.categories.economicCars.title") }}
          </h5>
          <p class="">
            {{
              $t("pages.carRentalsService.categories.economicCars.description")
            }}
          </p>

          <b-button
            :href="rentacarUrl"
            target="_blank"
            class="button"
            variant="light"
          >
            {{ $t("pages.carRentalsService.categories.button") }}
          </b-button>
        </swiper-slide>
        <swiper-slide class="item p-4 text-center">
          <b-img
            src="@/assets/foravila/images/services/premium-cars.jpeg"
            alt="Premium Rent a Car in Mallorca"
          ></b-img>
          <h5 class="font-weight-black text-uppercase mt-3">
            {{ $t("pages.carRentalsService.categories.premiumCars.title") }}
          </h5>
          <p class="">
            {{
              $t("pages.carRentalsService.categories.premiumCars.description")
            }}
          </p>

          <b-button
            :href="rentacarUrl"
            target="_blank"
            class="button"
            variant="light"
          >
            {{ $t("pages.carRentalsService.categories.button") }}
          </b-button>
        </swiper-slide>
        <swiper-slide class="item p-4 text-center">
          <b-img
            src="@/assets/foravila/images/services/automatic-cars.jpeg"
            alt="Automatic Rent a Car in Mallorca"
          ></b-img>
          <h5 class="font-weight-black text-uppercase mt-3">
            {{ $t("pages.carRentalsService.categories.automaticCars.title") }}
          </h5>
          <p class="">
            {{
              $t("pages.carRentalsService.categories.automaticCars.description")
            }}
          </p>

          <b-button
            :href="rentacarUrl"
            target="_blank"
            class="button"
            variant="light"
          >
            {{ $t("pages.carRentalsService.categories.button") }}
          </b-button>
        </swiper-slide>
        <swiper-slide class="item p-4 text-center">
          <b-img
            src="@/assets/foravila/images/services/large-cars.jpeg"
            alt="Large Rent a Car in Mallorca"
          ></b-img>
          <h5 class="font-weight-black text-uppercase mt-3">
            {{ $t("pages.carRentalsService.categories.largeCars.title") }}
          </h5>
          <p class="mb-6">
            {{ $t("pages.carRentalsService.categories.largeCars.description") }}
          </p>
          <div class="button">
            <b-button :href="rentacarUrl" target="_blank" variant="light">
              {{ $t("pages.carRentalsService.categories.button") }}
            </b-button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { BImg, BButton } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
  components: {
    BImg,
    BButton,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 16,
        freeMode: false,
        grabCursor: true,
        loop: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0
      }
    };
  },
  computed: {
    rentacarUrl() {
      return `https://www.autoslagoon.com/?partner=foravilarentals-guests`;
    }
  }
};
</script>
<style lang="scss" scoped>
.categories {
  .swiper-container {
    padding-top: 6rem;
    @media (min-width: 992px) {
      padding-top: 6rem;
    }
    position: relative;
    .swiper-full-width {
      padding-left: 0;
      padding-right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 2rem;
      color: #ffcd00;
      background-color: rgba(255, 205, 0, 0.15);
      padding: 2rem;
      &::after {
        font-size: 1.2rem;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: unset;
      right: 5rem;
    }
  }
  .item {
    position: relative;
    width: 300px;
    height: auto !important;
    @media (min-width: 992px) {
      width: 400px;
    }
    background: rgb(255, 255, 255);
    background: linear-gradient(
      55deg,
      rgba(255, 255, 255, 50) 0%,
      rgb(255, 255, 255) 100%
    );
    img {
      width: 10rem;
    }
    .button {
      position: absolute;
      bottom: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
