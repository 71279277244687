<template>
  <div
    class="car-rental-hero-bg d-flex flex-column justify-content-center px-3 px-lg-6 py-6 py-lg-10"
  >
    <h1
      class="text-uppercase text-white font-weight-black"
      v-html="$t('pages.carRentalsService.hero.title')"
    ></h1>
    <p class="text-white">
      {{ $t("pages.carRentalsService.hero.subtitle") }}
    </p>
    <div>
      <b-button
        :href="rentacarUrl"
        target="_blank"
        variant="primary"
        class="py-4 px-6 mt-3"
        >{{ $t("pages.carRentalsService.hero.button") }}</b-button
      >
    </div>
    <div class="logo-img mt-5">
      <p class="text-white mb-1">
        {{ $t("pages.localFoodService.heroCollaboration") }}
      </p>
      <b-img src="@images/logos/lagoon-logo.svg" alt="Autos Lagoon Logo" />
    </div>
  </div>
</template>
<script>
import { BButton, BImg } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BImg
  },
  computed: {
    rentacarUrl() {
      return `https://www.autoslagoon.com/?partner=foravilarentals-guests`;
    }
  }
};
</script>
<style lang="scss" scoped>
.car-rental-hero-bg {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.853),
      rgba(92, 92, 92, 0.712)
    ),
    url("~@/assets/foravila/images/services/rent-a-car-hero-bg.png");
  background-position: center center;
  background-size: cover;
  h1,
  p {
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: 50%;
    }
  }
  h1 {
    font-size: 1.7rem;
    @media (min-width: 768px) {
      font-size: 2.7rem;
    }
  }

  p {
    font-size: 1.1rem;
    @media (min-width: 768px) {
      font-size: 1.3rem;
    }
  }

  .logo-img {
    img {
      max-width: 6rem;
      @media (min-width: 992px) {
        max-width: 10rem;
      }
    }
    p {
      font-size: 0.8rem;
    }
  }
}
</style>
