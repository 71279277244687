<template>
  <div>
    <car-rental-hero />
    <car-rental-categories />
    <car-rental-highlights />

    <ForavilaGuarantee />
  </div>
</template>
<script>
import CarRentalHero from "@/views/foravila/services/car-rental/components/CarRentalHero.vue";
import CarRentalCategories from "@/views/foravila/services/car-rental/components/CarRentalCategories.vue";
import CarRentalHighlights from "@/views/foravila/services/car-rental/components/CarRentalHighlights.vue";
import ForavilaGuarantee from "@/views/foravila/services/components/ForavilaGuarantee.vue";

export default {
  components: {
    CarRentalHero,
    CarRentalCategories,
    CarRentalHighlights,
    ForavilaGuarantee
  }
};
</script>
