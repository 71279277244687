<template>
  <div class="guarantee d-flex align-items-center flex-column my-5">
    <span class="small"> {{ $t("pages.localFoodService.mainGuarantee") }}</span>
    <a href="https://www.foravilarentals.com" target="_blank">
      <b-img
        class="mt-3"
        src="@/assets/foravila/images/logos/foravila-rentals-logo.svg"
      />
    </a>
  </div>
</template>
<script>
import { BImg } from "bootstrap-vue";

export default {
  components: {
    BImg
  },

  computed: {
    locale() {
      return this.$i18n.locale.split("-")[0];
    }
  }
};
</script>
<style lang="scss">
.guarantee {
  img {
    max-width: 12rem;
    max-height: 12rem;
  }
}
</style>
