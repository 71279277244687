<template>
  <b-card id="highlights-section" class="py-4 border-0">
    <b-row class=" mb-6">
      <b-col>
        <h1
          class="text-uppercase text-center fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
        >
          {{ $t("pages.carRentalsService.highlights.title") }}
        </h1>
        <h4
          class="fr-subtitle-mobile-size text-center fr-subtitle-desktop-size"
        >
          {{ $t("pages.carRentalsService.highlights.subtitle") }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-4 mb-lg-0 text-center" cols="12" md="6" lg="3">
        <feather-icon icon="HeadphonesIcon" class="text-primary" size="40" />
        <h4 class="text-uppercase mt-3 font-weight-black text-secondary">
          {{ $t("pages.carRentalsService.highlights.localSupport.title") }}
        </h4>
        <p class="mt-3 mb-0 text-secondary">
          {{
            $t("pages.carRentalsService.highlights.localSupport.description")
          }}
        </p>
      </b-col>
      <b-col class="mb-4 mb-lg-0 text-center" cols="12" md="6" lg="3">
        <feather-icon icon="BriefcaseIcon" class="text-primary" size="40" />
        <h4 class="text-uppercase mt-3 font-weight-black text-secondary">
          {{ $t("pages.carRentalsService.highlights.airportService.title") }}
        </h4>
        <p class="mt-3 mb-0 text-secondary">
          {{
            $t("pages.carRentalsService.highlights.airportService.description")
          }}
        </p>
      </b-col>
      <b-col class="mb-4 mb-lg-0 text-center" cols="12" md="6" lg="3">
        <feather-icon icon="ShieldIcon" class="text-primary" size="40" />
        <h4 class="text-uppercase mt-3 font-weight-black text-secondary">
          {{ $t("pages.carRentalsService.highlights.freeCancellation.title") }}
        </h4>
        <p class="mt-3 mb-0 text-secondary">
          {{
            $t(
              "pages.carRentalsService.highlights.freeCancellation.description"
            )
          }}
        </p>
      </b-col>
      <b-col class=" mb-lg-0 text-center" cols="12" md="6" lg="3">
        <feather-icon icon="HomeIcon" class="text-primary" size="40" />
        <h4 class="text-uppercase mt-3 font-weight-black text-secondary">
          {{ $t("pages.carRentalsService.highlights.onsiteDelivery.title") }}
        </h4>
        <p class="mt-3 mb-0 text-secondary">
          {{
            $t("pages.carRentalsService.highlights.onsiteDelivery.description")
          }}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol
  }
};
</script>
<style lang="scss" scoped>
#highlights-section {
  h4 {
    font-size: 1.1rem;
    @media (min-width: 768px) {
      font-size: 1.286rem;
    }
  }
}
</style>
